/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../common-components/Heading";
import PageFooter from "../common-components/PageFooter";
import config from "../../config.json";
import DownImg from "../../images/down.gif";
import { BureauContext } from "../Context/BureauContext";
import { encryptData } from "../../encrypt";

const SanctionAcceptance = () => {
    const navigate = useNavigate();
    const [scroll, setScroll] = useState(4000);
    //const [sanctionHtml, setData] = useState({ __html: "" });
    const [disable, setButton] = useState(true);
    const [showSanction, setSanction] = useState(false);
    const userToken = sessionStorage.getItem('token');
    const api_url = config.API_URL;
    const { popupData, setPopUpData, checkStateByMiddileware, sanctionHtml, setsanctionHtml, checkLocationPermission} = useContext(BureauContext);
    const latitude = sessionStorage.getItem("latitude");
    const longitude = sessionStorage.getItem("longitude");

    const getSanction = async () => {
        checkStateByMiddileware();
        const headers = {
            "Authorization": `Bearer ${userToken}`,
           'latlong' : `${latitude},${longitude}`,
        }
        document.getElementById('waitingLoader').style.display = 'block';
        await fetch(api_url + `/sanction-acceptance`, { headers }).then((res) => {
            return res.json();
        }).then((result) => {
            if (result.status === "token") {
                document.getElementById('waitingLoader').style.display = 'none';
                sessionStorage.clear();
                navigate('/login');
            } else if (result.status === "expired") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bureau_checked : true});
            }else if(result.status === "hold") {
                document.getElementById('waitingLoader').style.display = 'none';
                setPopUpData({...popupData,is_bank_hold : true});
            } else if (result.status === "success") {
                setSanction(true);
                console.log("sanction letter",result.data.sanction)
                console.log("showSanction letter",showSanction);
                setsanctionHtml({ __html: result.data.sanction });
                setButton(false);
                document.getElementById('waitingLoader').style.display = 'none';
            }else if (result.status === 'fail' && result.is_rejected === true) {
                document.getElementById('waitingLoader').style.display = 'none';
                if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                navigate("/regrate");
            } else if (result.status === "fail") {
                document.getElementById('waitingLoader').style.display = 'none';
                toast.error(result.errors);
            }
        }).catch((err) => {
            document.getElementById('waitingLoader').style.display = 'none';
            toast.error(err);
        })
    }

    const acceptSanction = async () => {
        checkStateByMiddileware();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
                'latlong' : `${latitude},${longitude}`,
            },
        };
        if(checkLocationPermission()){
            document.getElementById('waitingLoader').style.display = 'block';
            fetch(api_url + `/accept-sanction`, requestOptions).then((res) => {
                return res.json();
            }).then((result) => {
                if (result.status === "token") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors);
                    sessionStorage.clear();
                    navigate('/login');
                } else if (result.status === 'expired') {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bureau_checked : true});
                }else if(result.status === "hold") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    setPopUpData({...popupData,is_bank_hold : true});
                } else if (result.status === "success") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.message);
                    navigate('/mitc-acceptance');
                }else if (result.status === 'fail' && result.is_rejected === true) {
                    document.getElementById('waitingLoader').style.display = 'none';
                    if(result.hasOwnProperty('data')){
                    sessionStorage.setItem('status_flag', result.data.appStatus.comment)
                }
                    navigate("/regrate");
                } else if (result.status === "fail") {
                    document.getElementById('waitingLoader').style.display = 'none';
                    toast.error(result.errors);
                }
            })
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    useEffect(() => {
        if(checkLocationPermission()){
            getSanction();
        }else{
            toast.error("Please allow your device location", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }, []);
    const goToDown = (val) => {
        const scrollmodal = document.querySelector('.modal-body');
        scrollmodal.scrollTo({
            top: val,
            left: 0,
            behavior: 'smooth'
        })
        setScroll(val + 4000);
    };
    return (
        <>
            <div className="pt-3">
                <Heading Description="You are getting ready to build a future-ready company" BadgeDescription="Sanction Summary" user />
            </div>
            <div className="container my-4">
                <div className="card shadow-sm downscroll">
                    <a className="text-end card-header border-0 bg-transparent" onClick={() => goToDown(scroll)} > <img src={DownImg} alt="Down arrow" width={25} className="cursor" /></a>
                    <div className="card-body modal-body acceptanceheight ">
                        {showSanction &&
                            <div className="acceptancewidth" dangerouslySetInnerHTML={sanctionHtml}></div>
                        }
                        <PageFooter setDisabled={disable} BtnName="I SUBMIT" TermsCondition="By accepting, I confirm to have read the sanction terms" ButtonClick={() => acceptSanction() }/>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SanctionAcceptance;